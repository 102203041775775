const DIRECTORY_ACTUAL_GOODS_ROUTES = {
    path: 'directory/actual-goods',
    name: 'directory-actual-goods',
    redirect: { name: 'actual-goods-list' },
    component: () => import('@/views/pages/Directories/goods/actual/DirectoryActualGoodsPage.vue'),
    children: [
        {
            path: 'list',
            name: 'actual-goods-list',
            meta: { transition: 'slide-left' },
            component: () => import('@/views/pages/Directories/goods/actual/list/DirectoryActualGoodsListPage.vue')
        },
        {
            path: ':actualgoodsId',
            name: 'current-actualgoods-actualgoods',
            meta: { transition: 'slide-right' },
            redirect: { name: 'current-actualgoods-goods' },
            // redirect: { name: 'current-event-basic' },
            component: () => import('@/views/pages/Directories/goods/actual/current/DirectoryActualGoodsCurrentPage.vue'),
            children: [
                {
                    path: 'actualgoods',
                    name: 'current-actualgoods',
                    component: () => import('@/views/pages/Directories/goods/actual/current/tabs/basic/tab.tsx')
                },
                {
                    path: 'goods',
                    name: 'current-actualgoods-goods',
                    component: () => import('@/views/pages/Directories/goods/actual/current/tabs/goods/tab.tsx')
                } 
            ],
        },
    ]
};

export default DIRECTORY_ACTUAL_GOODS_ROUTES;
